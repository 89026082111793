```
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.deletedcol {
  background-color: #ff7f7f;
}
.transmittedcol {
  background-color: #90ee90;
}
table {
  table-layout: fixed;
}
</style>
<template>
  <div class="card card-custom">
    <div class="full-load" v-if="generateReport">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="card-body">
      <div class="row" v-if="AppType">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by enrollment no or tcn or name or appointment no "
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="AppType" />
      <enrollmentDetailModal
        :key="key"
        v-if="enrollmentDetailModalShow"
        v-on:closemodal="closeModal"
        :loading-data="loadingData"
        :has-data="hasData"
        :show-modal="enrollmentDetailModalShow"
        :enrollment-data="enrollmentData"
        :index-num="currentIndex"
        :display-logs="displayLogs"
        @reLoadTable="reTableReload"
      ></enrollmentDetailModal>

      <div class="row" v-if="AppType">
        <canvas
          id="barcode"
          class="d-none"
          style="width: inherit; height: 95px"
        ></canvas>
        <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @change="changeYear()"
              v-model="dateRageYear"
              name=""
              class="form-control"
              id=""
            >
              <option v-for="item in yearOptions" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @change="changeDateRange()"
              name=""
              class="form-control"
              id=""
              v-model="dateRageType"
            >
              <option value="today">Today</option>
              <option value="this_week">This Week</option>
              <option value="last_week">Last Week</option>
              <option value="this_month">This Month</option>
              <option value="last_month">Last Month</option>
              <option value="last_3_month">Last 3 Month</option>
              <option value="1">January</option>
              <option value="2">Febuary</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>

              <option value="custom">Custom</option>
            </select>
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_from"
              class="form-control"
              @change="change_date"
            />
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_to"
              class="form-control"
              @change="change_date"
            />
          </div>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="serviceOutlet"
              @change="loadServices()"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Location</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="service"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Service</option>
              <option
                v-for="(citem, index) in getServices"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="transmit_status"
              class="form-control"
            >
              <option value="all">All Transmit Status</option>
              <option value="000">Draft</option>
              <option value="100">Ready To Transmit</option>
              <option value="200">Transmitted/Completed</option>
              <!-- <option value="Refund">Refunded</option> -->
            </select>
          </div>
        </div>

        <!-- <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <label for=""> Deleted </label>
            <select
              @keypress.enter="appData()"
              class="form-control"
              v-model="resubmit_status"
            >
              <option value="all">All</option>
              <option value="000">Not submitted</option>
              <option value="100">To Confirm</option>
              <option value="200">Confirmed</option>
            </select>
          </div>
        </div> -->

        <div class="col-md-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="display_deleted"
              class="form-control"
            >
              <option value="all">Display All</option>
              <option value="False">Active</option>
              <option value="True">Deleted</option>
              <!-- <option value="Refund">Refunded</option> -->
            </select>
          </div>
        </div>
        <div class="col-md-1">
          <button class="btn btn-outline-info mr-2" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button>
        </div>

        <!-- <div class="col-md-1" style="width:50%!important;"></div> -->
        <!-- <div class="col-md-3 mt-7" v-if="dateRageType != 'custom'"> -->
        <!-- <button class="btn btn-outline-info mr-2" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button> -->
        <!-- <download-excel
            class="btn btn-primary"
            :data="enrollments"
            :header="header"
            :worksheet="todays_date"
            name="enrollment-report.xls"
            :fields="json_fields"
          >
            <i class="test-white fa fa-file-excel"></i>Export to csv
          </download-excel> -->
        <!-- </div> -->
      </div>
      <!-- <hr v-if="AppType" />
      <div
        class="row"
        v-if="enrollments.length > 0 && !getLoadingData && AppType"
      >
        <div class="col-md-12"> -->
      <!-- <download-excel
            class="btn btn-primary ml-3"
            style="float:right"
            :data="appointments"
            :fields="json_fields"
            :worksheet="date_from + ' - ' + date_to"
            name="Appointment List.xls"
          >
            <i class="test-white fa fa-download"></i> Download Excel
          </download-excel> -->
      <!-- <a
            @click="refreshData()"
            href="#"
            class="btn btn-secondary font-weight-bolder"
            style="float: right"
          >
            <i class="text-white fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div> -->
      <hr v-if="enrollments.length > 0 && !getLoadingData && AppType" />
      <div class="row" v-if="enrollments.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <div class="float-right" v-if="dateRageType != 'custom'">
            <!-- <button class="btn btn-outline-info mr-2" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button> -->
            <download-excel
              class="btn btn-primary"
              :fetch="fetchData"
              :header="header"
              :worksheet="todays_date"
              name="enrollment-report.xls"
              :fields="json_fields"
              :before-generate="startDownload"
              :before-finish="finishDownload"
            >
              <i class="test-white fa fa-file-excel"></i>
            </download-excel>
          </div>
        </div>
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr
              v-for="(item, index) in enrollments"
              :key="index"
              :class="[
                item.is_deleted ? 'deletedcol' : '',
                !item.is_deleted &&
                (item.transmit_status == '600' ||
                  item.transmit_status == '300' ||
                  ((item.transmit_status == '200' ||
                    item.transmit_status == '500') &&
                    item.service_type == 'fingerprintcard'))
                  ? 'transmittedcol'
                  : ''
              ]"
            >
              <td>
                <h6 style="font-size: 13px; font-weight: 400">
                  {{
                    item.enroll_date != "" && item.enroll_date != null
                      ? item.enroll_date
                      : dateFormat(item.created_at)
                  }}
                </h6>
              </td>
              <td class="text-center">
                <a
                  href="javascript:void;"
                  @click="openEnrollmentDetail(item.id, index)"
                >
                  {{ item.id }}</a
                >
              </td>
              <td class="text-center">
                {{ item.appointment_id }}
              </td>
              <td>
                <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                {{
                  item.applicant == null
                    ? item.fname + " " + item.mname + " " + item.lname
                    : item.applicant
                }}
                <!-- {{ item.fname }} {{ item.mname }} {{ item.lname }} -->
              </td>

              <td>
                {{ item.service_name }}
              </td>
              <td>
                {{ item.originating_location_name }}
              </td>
              <td class="text-center">
                {{ item.tcn != "" || item.tcn != null ? item.tcn : "-" }}
              </td>
              <td class="text-center">
                {{ item.print_count }}
              </td>
              <td class="text-center">
                {{ item.resubmit_count }}
              </td>
              <td class="text-center">
                <div>
                  <div v-if="item.service_type == 'fdle'">
                    <div v-if="item.acha_submit_status == '300'" class="d-flex">
                      Submitted

                      <span
                        v-if="
                          item.acha_response &&
                            item.acha_response.IsSuccessful == 'false'
                        "
                        style="color: red; margin-left: 2px"
                      >
                        <i
                          class="las la-exclamation-circle"
                          style="font-size: 20px; color: red"
                        ></i
                      ></span>
                      <span
                        v-if="
                          item.acha_response &&
                            item.acha_response.IsSuccessful == 'true'
                        "
                        style="color: red; margin-left: 2px"
                      >
                        <i
                          class="fa fa-check"
                          style="font-size: 20px; color: blue"
                        ></i>
                      </span>
                    </div>

                    <div
                      v-else-if="item.acha_submit_status != '300'"
                      class="d-flex"
                    >
                      Not Submitted
                      <span v-if="item.acha_submit_status == '200'">
                        <i
                          class="las la-exclamation-circle"
                          style="font-size: 20px; color: red"
                        ></i
                      ></span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span
                  v-if="
                    (item.transmit_status == '200' ||
                      item.transmit_status == '500') &&
                      item.service_type != 'fingerprintcard'
                  "
                  >Ready To Transmit</span
                >
                <span
                  v-else-if="
                    (item.transmit_status == '200' ||
                      item.transmit_status == '500') &&
                      item.service_type == 'fingerprintcard'
                  "
                  >Final</span
                >

                <span
                  v-else-if="
                    (item.transmit_status == '300' ||
                      item.transmit_status == '600') &&
                      item.service_type == 'fingerprintcard'
                  "
                  >Final</span
                >
                <span
                  v-else-if="
                    (item.transmit_status == '300' ||
                      item.transmit_status == '600') &&
                      item.service_type != 'fingerprintcard'
                  "
                  >Transmitted</span
                >
                <span
                  v-else-if="
                    item.transmit_status == '100' ||
                      item.transmit_status == '400'
                  "
                  >Draft</span
                >

                <span v-else></span>
              </td>
              <td>
                <span
                  v-if="
                    (item.transmit_status == '300' ||
                      item.transmit_status == '600') &&
                      item.service_type != 'fingerprintcard'
                  "
                >
                  {{
                    (item.transmitted_by == null ||
                      item.transmitted_by == "") &&
                    (item.transmit_status == "300" ||
                      item.transmit_status == "600")
                      ? item.transmit_status == "300" ||
                        item.transmit_status == "600"
                        ? item.updated_by_user
                        : ""
                      : item.transmitted_by
                  }}
                </span>
                <span v-else> </span>
              </td>
              <td>
                <router-link
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  :to="{
                    name: 'client.storeforward.tcn.pdf',
                    params: { tcn: item.tcn, id: item.id }
                  }"
                  ><i class="fa fa-print ml-1"></i>
                </router-link>
              </td>
              <!-- <td>
                <a
                  v-if="
                    (item.transmit_status == '200' ||
                      item.transmit_status == '500') &&

!item.is_deleted
                  "
                  href="javascript:void()"
                  @click="transmit(item.id)"
                  class="btn btn-light-info"
                >
                  <i class="fa fa-share"></i>
                </a>
              </td> -->
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="enrollments.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no appointment(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_CLIENT_ENROLLMENT,
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_ENROLLMENT,
  FETCH_MASTER_COMPANIES,
  ENROLLMENT_LOGS,
  TRANSMITSTATUSCHANGE,
  DOWNLOAD_CLIENT_ENROLLMENT
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import vlPagination from "@/includes/table/Pagination";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import enrollmentDetailModal from "@/components/frontend/modal/enrollmentdetail";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    vTable,
    vlPagination,
    vDataLoader,
    enrollmentDetailModal,
    downloadExcel: JsonExcel
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getServices"])
  },
  props: {
    AppType: {
      type: Boolean,
      default: true
    },
    DashType: {
      type: String,
      default: "appointment_list"
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    dateFormat(date) {
      if (date == null) {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    },
    payementStatus(status) {
      if (status == "C") {
        return "Card";
      }
      if (status == "A") {
        return "Abbreviation";
      }
      if (status == "O") {
        return "On-site";
      }
      if (status == "Cancel") {
        return "Cancel";
      }
    },

    apptStatus(status) {
      var s = "";
      switch (status) {
        case "P":
          s = "Pending";
          break;

        case "C":
          s = "Completed";
          break;

        case "U":
          s = "No-Show";
          break;
        case "Cancel":
          s = "Canceled";
          break;
      }
      return s;
    }
  },
  data() {
    return {
      loadingMessage: "",
      generateReport: false,
      downloadData: [],
      dateRageType: "custom",
      enrollmentDetailModalShow: false,
      json_fields: {
        Date: "enroll_date",
        "Enrollment No": "id",
        "Appointment No": "appointment_id",
        Applicant: "applicant",
        TCN: "tcn",
        Service: "service_name",
        Location: "client_name",
        Status: {
          field: "transmit_status",
          callback: value => {
            if (value.transmit_status == "000") {
              return "Draft";
            } else if (
              value.transmit_status == "100" ||
              value.transmit_status == "400"
            ) {
              return "Draft";
            } else if (
              (value.transmit_status == "200" ||
                value.transmit_status == "500") &&
              value.service_type == "fingerprintcard"
            ) {
              return "Final";
            } else if (
              (value.transmit_status == "200" ||
                value.transmit_status == "500") &&
              value.service_type != "fingerprintcard"
            ) {
              return "Ready To Transmit";
            } else if (
              value.transmit_status == "300" ||
              value.transmit_status == "600"
            ) {
              return "Transmitted";
            } else {
              return "";
            }
          }
        },
        "Transmit by": "transmitted_by"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      getLoadingData: false,
      columns: [
        {
          name: "date",
          width: "8%",
          label: "Date",
          class: ""
        },
        {
          name: "Enr No",
          width: "5%",
          label: "Enr No.",
          class:"text-center"
        },
        {
          name: "Appointment No",
          width: "6%",
          label: "App No.",
          class:"text-center"
        },
        {
          name: "applicant",
          width: "10%",
          label: "Applicant",
          class: ""
        },
        {
          name: "Service",
          width: "10%",
          label: "Service",
          class: ""
        },
        {
          name: "Location",
          width: "10%",
          label: "Location",
          class: ""
        },
        {
          name: "tcn",
          width: "10%",
          label: "TCN",
          class: "text-center"
        },
        {
          name: "Print Count",
          width: "5%",
          label: "Print Count",
          class: "text-center"
        },
        {
          name: "Resubmit Count",
          width: "7%",
          label: "Resubmit Count",
          class: "text-center"
        },
        {
          name: "AHCA Status",
          width: "10%",
          label: "AHCA Status",
          class: ""
        },
        {
          name: "status",
          width: "8%",
          label: "Status",
          class: ""
        },
        {
          name: "Transmitted by",
          width: "9%",
          label: "Transmitted by",
          class: ""
        },

        {
          name: "release form",
          width: "6%",
          label: "Release Form",
          class: ""
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Enrollment List",
          route: ""
        }
      ],
      displayLogs: true,
      service: "all",
      serviceOutlet: "all",
      dateRageYear: this.$moment(new Date()).year(),
      todays_date: this.$moment(new Date()).format("MM-DD-YYYY"),
      date_from: this.$moment(new Date()).format("YYYY-MM-DD"),
      date_to: this.$moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD"),
      status: "all",
      yearOptions: [],
      payment_status: "all",
      transmit_status: "all",
      resubmit_status: "all",
      display_deleted: "all",
      enrollments: [],
      header: ["Enrollment Reports"],
      offset: 0,
      limit: 20,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      selectedAppId: 0,

      enrollmentData: {},
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      msg: "",
      search_text: "",
      key: 0
    };
  },

  mounted() {
    var year = 2021;
    var this_year = this.$moment(new Date()).year();
    while (this_year >= year) {
      this.yearOptions.push(year);
      year = year + 1;
    }

    this.$store.dispatch(FETCH_MASTER_COMPANIES);
    if (window.localStorage.getItem("appoitmentsearch") != null) {
      let search = JSON.parse(window.localStorage.getItem("appoitmentsearch"));
      this.service = search.service;
      this.serviceOutlet = search.service_outlet
        ? search.service_outlet
        : "all";
      this.date_from = search.date_from
        ? search.date_from
        : this.$moment(new Date()).format("YYYY-MM-DD");
      this.date_to = search.date_to
        ? search.date_to
        : this.$moment(new Date())
            .add(7, "days")
            .format("YYYY-MM-DD");
      this.$store.dispatch(
        FETCH_SERVICES,
        search.serviceOutlet ? search.serviceOutlet : "all"
      );
    } else {
      this.$store.dispatch(FETCH_SERVICES, "all");
      this.serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    }
    this.$store.dispatch(FETCH_CLIENTS);
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.appData();
    if (!this.AppType) {
      if (this.DashType == "today") {
        this.columns = [
          {
            name: "App_no",
            width: "10%",
            label: "App No.",
            class: ""
          },
          {
            name: "applicant",
            width: "",
            label: "Applicant",
            class: ""
          },
          {
            name: "Service/location",
            width: "",
            label: "Service/Location",
            class: ""
          }
        ];
      } else {
        this.columns = [
          {
            name: "date",
            width: "",
            label: "Date",
            class: ""
          },
          {
            name: "App_no",
            width: "10%",
            label: "App No.",
            class: ""
          },
          {
            name: "applicant",
            width: "",
            label: "Applicant",
            class: ""
          },
          {
            name: "Service/location",
            width: "",
            label: "Service/Location",
            class: ""
          }
        ];
      }
    }
  },

  methods: {
    search() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    transmit(enrollment_id) {
      this.$swal({
        title: "Are you sure?",
        text: "you want to transmit enrollment no " + enrollment_id + " ?",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          let data = {
            id: enrollment_id,
            type: "transmit"
          };
          this.$store
            .dispatch(TRANSMITSTATUSCHANGE, data)
            .then(res => {
              this.$toastr.s(res.msg);
              this.appData();
            })
            .catch(err => {
              this.$toastr.e(err.response.data.msg);
            });
        }
      });
    },
    reTableReload(index) {
      this.enrollments[index].payment_status = "Refund";
    },
    advSearch() {
      this.search_text = "";
      this.search();
    },
    closeModal() {
      this.enrollmentDetailModalShow = false;
    },
    openEnrollmentDetail(enrollmentId, index) {
      this.currentIndex = index;
      this.enrollmentDetailModalShow = true;
      this.selectedAppId = enrollmentId;
      this.loadingData = true;
      var data = {
        enrollment_id: enrollmentId
      };
      // console.log(data);
      this.$store.dispatch(ENROLLMENT_LOGS, {
        enrollment_id: enrollmentId
      });
      this.$store
        .dispatch(FETCH_ENROLLMENT, data)
        .then(res => {
          // console.log(res);
          this.enrollmentData = res.data;
          this.loadingData = false;
          this.hasData = res.hasData;
          this.displayLogs = true;
          this.key += 1;
          this.enrollmentData = res.data;
        })
        .catch(() => {
          alert();
          this.loadingData = false;
        });
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    changeDateRange() {
      var isNotNumber = isNaN(this.dateRageType);
      if (isNotNumber) {
        switch (this.dateRageType) {
          case "today":
            this.date_from = moment(new Date()).format("YYYY-MM-DD");
            this.date_to = moment(new Date()).format("YYYY-MM-DD");
            this.dateRageYear = this.$moment(new Date()).year();
            break;
          case "this_week":
            this.date_from = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
            this.date_to = moment()
              .endOf("week")
              .format("YYYY-MM-DD");
            this.dateRageYear = this.$moment(new Date()).year();

            break;
          case "last_week":
            this.date_from = moment()
              .subtract(1, "weeks")
              .startOf("week")
              .format("YYYY-MM-DD");
            this.date_to = moment()
              .subtract(1, "weeks")
              .endOf("week")
              .format("YYYY-MM-DD");
            this.dateRageYear = this.$moment(new Date()).year();

            break;
          case "this_month":
            this.date_from = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
            this.date_to = moment()
              .endOf("month")
              .format("YYYY-MM-DD");
            this.dateRageYear = this.$moment(new Date()).year();
            break;
          case "last_month":
            this.date_from = moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            this.date_to = moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
            this.dateRageYear = this.$moment(new Date()).year();

            break;
          case "last_3_month":
            this.date_from = moment()
              .subtract(3, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            this.date_to = moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
            this.dateRageYear = this.$moment(new Date()).year();

            break;
          case "custom":
            break;
        }
      } else {
        var year = this.dateRageYear;
        this.date_from = moment()
          .set("year", parseInt(year))
          .set("month", parseInt(this.dateRageType) - 1)
          .startOf("month")
          .format("YYYY-MM-DD");
        this.date_to = moment()
          .set("year", parseInt(year))
          .set("month", parseInt(this.dateRageType) - 1)
          .endOf("month")
          .format("YYYY-MM-DD");
      }
    },
    change_date() {
      this.dateRageType = "custom";
    },
    changeYear() {
      this.dateRageType = "custom";
      this.date_from = moment()
        .set("year", parseInt(this.dateRageYear))
        .set("month", 0)
        .startOf("month")
        .format("YYYY-MM-DD");
      this.date_to = moment()
        .set("year", parseInt(this.dateRageYear))
        .set("month", 0)
        .endOf("month")
        .format("YYYY-MM-DD");
    },

    selectedPage(n) {
      this.pagination.current_page = n;
      this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.appData();
    },
    loadServices() {
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
    },
    async fetchData() {
      this.downloadData = [];
      this.header = ["Enrollment Reports"];
      this.header.push("Date: " + this.date_from + " to " + this.date_to);
      if (this.serviceOutlet != "all") {
        var location_data = this.getClients.filter(
          item => item.id === this.serviceOutlet
        )[0];
        this.header.push("Location: " + location_data.name);
      } else {
        this.header.push("Location: All");
      }
      if (this.service != "all") {
        var service_data = this.getServices.filter(
          item => item.id === this.service
        )[0];
        this.header.push("Service: " + service_data.name);
      } else {
        this.header.push("Service: All");
      }
      if (this.transmit_status != "all") {
        if (this.transmit_status == "000") {
          this.header.push("Transmit Status: Draft");
        } else if (this.transmit_status == "100") {
          this.header.push("Transmit Status: Draft");
        } else if (this.transmit_status == "200") {
          this.header.push("Transmit Status: Ready To Transmit");
        } else if (this.transmit_status == "300") {
          this.header.push("Transmit Status: Completed");
        }
      } else {
        this.header.push("Transmit Status: All");
      }
      var data = {
        service: this.service,
        service_outlet: this.serviceOutlet,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.currentUser.u.is_client ? "c" : "sp",
        status: this.status,
        payment_status: this.payment_status,
        transmit_status: this.transmit_status,
        resubmit_status: this.resubmit_status,
        page: this.pagination.current_page,
        search_text: this.search_text,
        display_deleted: this.display_deleted,
        dtype: "appointment_list"
      };
      let res = await this.$store.dispatch(DOWNLOAD_CLIENT_ENROLLMENT, data);
      return res.data;
    },

    startDownload() {
      this.generateReport = true;
      this.loadingMessage =
        "please wait <br> Your report is being generated...";
    },
    finishDownload() {
      this.generateReport = false;
      this.loadingMessage = "";
    },
    appData() {
      this.enrollments = [];
      this.getLoadingData = true;
      var data = {
        service: this.service,
        service_outlet: this.serviceOutlet,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.currentUser.u.is_client ? "c" : "sp",
        status: this.status,
        payment_status: this.payment_status,
        transmit_status: this.transmit_status,
        resubmit_status: this.resubmit_status,
        page: this.pagination.current_page,
        search_text: this.search_text,
        display_deleted: this.display_deleted,
        dtype: "appointment_list"
      };
      window.localStorage.setItem("appoitmentsearch", JSON.stringify(data));
      this.$store
        .dispatch(FETCH_CLIENT_ENROLLMENT, data)
        .then(res => {
          this.getLoadingData = false;
          this.enrollments = res.data;
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
          this.pagination.per_page_data = 15;

          // console.log(res);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    }
  }
};
</script>
